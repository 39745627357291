<template>
<div class="content">
  <Nav></Nav>
  <Navbj></Navbj>
  <div class="cont_inform">
     <div><img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/9.png" alt="">首页 >  <span>官方活动</span></div>
  </div>
  <div class="cont_activity">
     <div class="activity_list" >
         <div class="lsit_item" v-for="(item ,index) in lb_list" :key="index" @click="xqtz(item.id)">
           <img :src="item.img" alt="">
           <p>{{item.info}}</p>
           <p><i class="el-icon-time"/> {{item.addtime | formatDateAt}}   <i class="el-icon-d-arrow-right"/></p>
         </div>
     </div>
    <div class="activity_paging">
      <p class="paging_top" @click="onClick('prev')" >上一页</p>
      <p class="paging_item" @click="cut(item)" v-for="(item,index) in list" :class="currentPage == item ? 'paging_item_xz':''" :key="index">{{item}}</p>
      <p class="paging_bottom" @click="onClick('next')">下一页</p>
      <p class="paging_end" @click="onClick('last')">最后一页</p>
    </div>
  </div>
  <Btm></Btm>
</div>
</template>

<script>
import Nav from "@/components/yd-Nav/index"
import Navbj from "@/components/yd-Nav/nav_bj"
import Btm from "@/components/yd-Btmfooter/index"
import {gfhd_list, mes_classify_list} from "@/api/request/all";
import {mapState} from "vuex";
export default {
  name: "activity",
  data(){
    return{
      lb_list:[],
      total:0,//总长度
      currentPage:1, //选择的页
      pages:1, //当前页
      pagesLength:0, //总共几页 **/5
      list:[],//分页数据
    }
  },
  components:{
    Nav,
    Navbj,
    Btm
  },
  computed: {
    ...mapState(['user','seo']),
  },
  metaInfo() {
    return {
      title: this.seo[1].title || this.user.indextitle,
      meta: [
        { name: 'description', content: this.seo[1].description ||this.user.drp},
        { name: 'keywords', content:this.seo[1].keywords ||this.user.seo}
      ]
    };
  },
  mounted() {
    this.all_list();
  },
  methods:{
    //详情跳转
    xqtz(id){
      this.$router.push({
        //name:'/mesdetail',
        path:`/2/${id}.html`,
        // params:{
        //   type:2,
        //   id:id+'.html'
        // }
      })
    },
     all_list(){
       let list={
         page:1,
         pagenum:9
       }
       gfhd_list(list).then((res)=>{
         this.total=res.count;
         this.pages_fyxh();
         if (res.status == 1){
           this.lb_list= res.data;
         }

       })
     },
    cut(vla){
      window.scrollTo({ top: 0, behavior: 'auto' }); // 平滑滚动到顶部
      this.currentPage=vla;
      this.pages=vla;
      let list={
        page:this.currentPage,
        pagenum:9
      }
      gfhd_list(list).then((res)=>{
        if (res.status == 1){
          this.lb_list= res.data;
        }else {
          this.$message({
            message: '暂无数据',
            type: 'warning',
            offset:200
          });
        }

      })

    },
    //分页加载循环
    pages_fyxh(){
      this.pagesLength= Math.ceil(this.total / 9);
      console.log( this.pagesLength)
      let array =[];
      for (let i = 1 ; i<=this.pagesLength; i++){
        if (i < this.pages*5+1 && (this.pages-1)*5 < i){
          array.push(i)
        }
      }
      this.list=array;

    },
    //分页点击事件
    onClick(page) {
      // 这里处理点击事件
      if (page == 'prev' && this.pages > 1) {
        console.log('上一页')
        this.pages--;
        console.log(this.pages)
        this.cut(this.pages);

      } else if (page == 'next' && this.pages < this.pagesLength) {
        console.log('下一页')
        this.pages++;
        this.cut(this.pages);
      } else if (page == 'last') {
        if (this.pages == this.pagesLength ){
          this.$message({
            message: '已经是最后一页了!',
            type: 'warning',
            offset:200
          });
        }else {
          this.pages = this.pagesLength ;
          this.cut(this.pages);
        }
      }else {
        this.$message({
          message: '已经到底了',
          type: 'warning',
          offset:200
        });
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .content{

    .cont_inform{
      width: 100%;
      height: 1.65rem;
      background: #EEEEEE;
      div{
        width: 100%;
        margin:0 auto;
        height: 1.65rem;
        line-height: 1.65rem;
        img{
          width: 0.53rem;
          height: 0.65rem;
          vertical-align: sub;
          margin-right: 9px;
          margin-left: 0.63rem;
          position: relative;
          top: -0.08rem;
        }
        font-weight: 500;
        font-size: 0.7rem;
        color: #666666;
        span{
          color: #222222;
        }
      }
    }
    .cont_activity{
      width: 100%;
      background:#FFFFFF;
      padding-top: 0.5rem;
      padding-bottom: 1.73rem;
      min-height: 358px;
      .activity_list{
        width: 18rem;
        margin:0 auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .lsit_item{
          width: 8.75rem;
          height: 10rem;
          background: #EEEEEE;
          color: #333333;
          position: relative;
          border-radius: 0px 0px 6px 6px;
          margin-bottom: 0.5rem;
          img{
            width: 8.75rem;
            height: 5rem;
          }
          p:nth-child(2){
            width: 7.83rem;
            font-weight: bold;
            font-size: 0.7rem;
            line-height: 0.9rem;
            margin: 0 auto;
            position: absolute;
            top: 5.6rem;
            left: 50%;
            transform: translateX(-50%);
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            white-space: normal;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          p:nth-child(3){
            width: 7.83rem;
            position: absolute;
            top:8.6rem;
            left: 50%;
            transform: translateX(-50%);
            font-weight: 500;
            font-size: 0.6rem;
            color: #999999;
            .el-icon-d-arrow-right{
              float: right;
            }

          }
        }
        .lsit_item:hover{
          background:#FFA71F ;
          color:#FFFFFF;
          p:nth-child(3){
            color: #FFFFFF;
          }
        }
      }
      .activity_paging{
        width: 18rem;
        margin: 1rem auto 0;
        text-align: center;
        display: flex;
        justify-content: center;
        .paging_top{
          width: 3rem;
          height: 1.45rem;
          border-radius: 0.25rem;
         border: 1px solid #DADADA;
         font-weight: 500;
         font-size: 0.65rem;
         color: #666666;
         line-height: 1.45rem;
         margin: 0 5px;
       }
        .paging_bottom{
          width: 3rem;
          height: 1.45rem;
          border-radius: 0.25rem;
          border: 1px solid #DADADA;
          font-weight: 500;
          font-size: 0.65rem;
          color: #666666;
          line-height: 1.45rem;
          margin: 0 5px;
        }
        .paging_end{
          width: 3rem;
          height: 1.45rem;
          border-radius: 0.25rem;
          border: 1px solid #DADADA;
          font-weight: 500;
          font-size: 0.65rem;
          color: #666666;
          line-height: 1.45rem;
          margin: 0 5px;
        }
        .paging_item{
          width: 1.45rem;
          height: 1.45rem;
          border-radius: 4px;
          border: 1px solid #DADADA;
          line-height: 1.45rem;
          font-weight: 500;
          font-size: 0.6rem;
          color: #666666;
          margin: 0 5px;
        }
        .paging_item_xz{
          background: #FFA71F;
          color:#FFFFFF ;
        }
      }
    }
  }
</style>